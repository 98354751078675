import React from 'react';
import axios from 'axios';
import './SectionTheme.css';

// Import Config URL
import URL from '../../config';
import IMAGE_URL from '../../image-config';

// Import Other Components
import SectionThemeCard from './SectionThemeCard/SectionThemeCard';

// Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';

// Redux
import { connect } from 'react-redux';
import { addSections } from '../../actions/PackageActions';

// React Router Redirect
import { Link } from 'react-router-dom';

// Google Analytics
import ReactGA from 'react-ga';

class SectionTheme extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            TOID: this.props.match.params.TOID || null,
            TOName: this.props.match.params.TourOperatorName || null,
            name: '',
            sections: [],
            paid: false,
        }
    }

    // Axios Post Request with the Tour Operator ID to get the Section Information for a Company
    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Section Theme Page View`,
            label: `${this.props.match.params.TourOperatorName} - Section Theme`
        })

        axios.post(`${URL}/api/v1/tour-operator/get-operator-form-data`, {
            TOID: this.state.TOID
        }, {})
        .then((response) => {
            if(response.data.length > 0) {
                this.setState({
                    profileImage: response.data[0].ProfileImage
                })
                axios.post(`${URL}/api/v1/section/get-operator-section-data`, {
                    TOID: this.state.TOID
                }, {})
                .then((response) => {
                    if(response.data.length > 0) {
                        // Log the Sections for the Operator
                        this.setState({
                            sections: response.data,
                            paid: true
                        })
                    }
                })
            }
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.TOID !== null && this.state.TOName !== null && this.state.paid && this.state.sections.length > 0?
                    <div class="SectionTheme-Parent-Div">
                        <Grid item container xs={12}
                        justify="flex-start"
                        alignItems="center"
                        direction="column"
                        className="SectionTheme-Grid-Container">

                                <Grid item container xs={11}
                                justify="flex-start"
                                alignItems="flex-end"
                                className="SectionTheme-Logo-Container">
                                    <Grid item xs={12} sm={2}>
                                    {
                                        this.props.profileImage !== null?
                                        <img
                                        src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                                        class="SectionTheme-Logo"
                                        alt={`Company Logo`}/> :
                                        <div class="SectionTheme-Logo-Default"></div>
                                    }
                                    </Grid>

                                    <Grid item xs={12} sm={9}>
                                        <Typography variant="h4"
                                        className="TOIntro-Text">
                                            <Box fontWeight="600">
                                                Here are some themes we have available. Select the ones you want.
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container xs={11} sm={8}
                                justify="space-between"
                                alignItems="flex-start">
                                    {
                                        this.state.sections.map((sectionObject) => {
                                            return (
                                                <Grid item xs={12} sm={5}>
                                                    <SectionThemeCard
                                                    SID={sectionObject.SID}
                                                    Name={sectionObject.Name}
                                                    Description={sectionObject.Description}
                                                    SectionObject = {sectionObject}
                                                    TOID = {this.props.match.params.TOID}
                                                    TourOperatorName = {this.props.match.params.TourOperatorName}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>

                            <Grid item container xs={11} sm={8}
                            justify="flex-end"
                            alignItems="center">
                                <Grid item>
                                    {
                                        this.props.selectedSections.length > 0 ?
                                        <Button
                                        color="secondary"
                                        variant="contained"
                                        component={Link}
                                        to={`/${this.state.TOName}/${this.state.TOID}/experiences`}
                                        size="large">
                                            Continue <ArrowForward />
                                        </Button>
                                        :
                                        <Button variant="contained" color="secondary"
                                        size="large" disabled>
                                            Continue <ArrowForward />
                                        </Button>
                                    }

                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}


// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        profileImage: state.profileImage,
        selectedSections: state.selectedSections,
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        addSections: (section) => {
            dispatch(addSections(section))
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(SectionTheme);
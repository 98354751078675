import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
/*
import yellow from '@material-ui/core/colors/yellow';
import indigo from '@material-ui/core/colors/indigo';

  palette: {
      primary: {
          main: yellow['500']
      },
      secondary: {
          main: indigo['500']
      }
  },
  overrides: {
      MuiButton: {
          root: {
              color: yellow['500'],
          }
      }
  }
*/

let theme = createMuiTheme({
    typography: {
        "fontFamily": "Quicksand"
    }
});

theme = responsiveFontSizes(theme);
export default theme;
import React from 'react';
import './ItineraryMap.css';

//Leaflet
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

//Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

//MAPS
// https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
// https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png

class ItineraryMap extends React.Component {


  render() {
    return (
      <div>
        <Grid container
            justify="center"
            alignItems="center">
            <Grid item container xs={11}
            justify="flex-start"
            alignItems="flex-start">
            <Typography variant="h5"><strong>your experience locations</strong></Typography>
            </Grid>
        </Grid>
          {
              this.props.locations.length > 0 ?
                <Grid container
                justify="center"
                alignItems="center">
                    <Grid item container xs={11}>
                    <Map className="itnerary-map-map"
                    center={[this.props.locations[0].latitude, this.props.locations[0].longitude]}
                    zoom={7}>
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        {
                        this.props.locations.map(loc => {
                            return (
                            <Marker position={[loc.latitude, loc.longitude]}>
                                <Popup>{loc.title}</Popup>
                            </Marker>
                            )
                        })
                        }
                    </Map>
                    </Grid>
                </Grid>
            :
            null
          }
    </div>
    )
  }
}

export default ItineraryMap;
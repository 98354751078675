import React from 'react';
import './ItineraryDisplaySection.css';

//Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

// Image URL
import IMAGE_URL from '../../../image-config';

class ItineraryDisplaySection extends React.Component {
  render() {
    return (
      <div>
          <Grid container xs={12}
          direction="row"
          alignItems="center"
          justify="center">
          <Grid container xs={12}
          direction="row"
          alignItems="flex-end"
          justify="center">

            {/* FIRST COLUMN IN THE COMPONENT */}
            <Grid item container xs={6}
            direction="column"
            alignItems="center"
            justify="center">
                <Grid item xs={12}>
                    {
                     this.props.DisplayImages.length === 0 ?
                        <div class="itinerary-display-section-div-left">
                            <div class="itinerary-display-section-div-left-bg"></div>
                        </div>
                        :
                        <div class="itinerary-display-section-div-left">
                        <img src={`${IMAGE_URL}/form/${this.props.DisplayImages[0].Filename}`}
                        class="loading"
                        alt="Safari Animal"/>
                        </div>
                    }

                </Grid>
                <Grid item xs={12}>
                    {
                     this.props.DisplayImages.length < 2 ?
                        <div class="itinerary-display-section-div-left">
                            <div class="itinerary-display-section-div-left-bg-2"></div>
                        </div>
                        :
                        <div class="itinerary-display-section-div-left">
                        <img src={`${IMAGE_URL}/form/${this.props.DisplayImages[1].Filename}`}
                        class="loading"
                        alt="Safari Animal"/>
                        </div>
                    }
                </Grid>
            </Grid>

            {/* SECOND COLUMN IN THE COMPONENT */}
            <Grid item container xs={6}
            direction="column"
            alignItems="center"
            justify="center">
              <Hidden smDown>
                <Grid item
                direction="row">
                    <div class="itinerary-display-section-header-text">
                    <Typography variant="h4">Your Tanzania Adventure</Typography>
                    <div class="itinerary-display-section-header-mobile">
                    <Typography variant="subtitle1">An adventure with the best experiences for you.</Typography>
                    </div>
                    </div>
                </Grid>
              </Hidden>
              <Grid item container
              direction="row"
              alignItems="flex-end"
              justify="center">
                <Grid item xs={6}
                direction="column">
                    {
                     this.props.DisplayImages.length < 3 ?
                        <div class="itinerary-display-section-div-right">
                            <div class="itinerary-display-section-div-right-bg-1"></div>
                        </div>
                        :
                        <div class="itinerary-display-section-div-right">
                        <img src={`${IMAGE_URL}/form/${this.props.DisplayImages[2].Filename}`}
                        alt="Safari Animal"/>
                        </div>
                    }

                </Grid>
                <Grid item xs={6}
                direction="column">
                    {
                     this.props.DisplayImages.length < 4 ?
                        <div class="itinerary-display-section-div-right">
                            <div class="itinerary-display-section-div-right-bg-2"></div>
                        </div>
                        :
                        <div class="itinerary-display-section-div-right">
                        <img src={`${IMAGE_URL}/form/${this.props.DisplayImages[3].Filename}`}
                        alt="Safari Animal"/>
                        </div>
                    }
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          </Grid>

      </div>
    )
  }
}

export default ItineraryDisplaySection;
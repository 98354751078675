import React from 'react';
import axios from 'axios';

// Import Config URL
import URL from '../../config';

// Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';


// Redux
import { connect } from 'react-redux';

// React Router Redirect
import { Redirect } from 'react-router-dom';

import { setItineraryData } from '../../actions/PackageActions';

// Import Image URL
import IMAGE_URL from '../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class Itinerary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            TOID: this.props.match.params.TOID,
            TOName: this.props.match.params.TourOperatorName,
            open: false,
            redirect: false,
            paid: false
        }
    }

    // Axios Post Request with the Tour Operator ID to get the Company Information
    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Itinerary Page View`,
            label: `${this.props.match.params.TourOperatorName} - Itinerary Date`
        })

        axios.post(`${URL}/api/v1/tour-operator/get-operator-form-data`, {
            TOID: this.state.TOID
        }, {})
        .then((response) => {
            if(response.data.length > 0) {
                axios.post(`${URL}/api/v1/itinerary/generate-itinerary`, {
                    selectedExperiences: this.props.selectedExperiences,
                    selectedExperienceAddOns: this.props.selectedExperienceAddOns,
                    TOID: this.state.TOID,
                    approximateDays: this.props.approximateDays,
                    approximateDayVariance: this.props.approximateDayVariance,
                    CompanyName: this.props.CompanyName,
                    OperatorName: this.props.OperatorName,
                    ProfileImage: this.props.profileImage,
                    LuxuryLevel: this.props.luxuryLevel,
                    NumberOfTravellers: this.props.numberOfTravelers,
                    FullName: this.props.fullName,
                    AdventureStartDate: this.props.adventureStartDate,


                }, {})
                .then(async (response) => {
                    await this.props.setItineraryData(response.data);
                    await this.props.setItineraryData(response.data);
                    this.setState({
                        redirect: true
                    })
                })
            }
        })
    }


    render() {
        return (
            <div>
                {
                    this.state.redirect ?
                    <Redirect to={`/${this.state.TOName}/${this.state.TOID}/itineraries`} />
                    :
                    null
                }
                {
                    this.state.TOID !== null && this.state.TOName !== null ?
                    <div class="SectionTheme-Parent-Div">
                        <Grid item container xs={12}
                        justify="flex-start"
                        alignItems="center"
                        direction="column"
                        className="SectionTheme-Grid-Container">

                            <Grid item container xs={11}
                            justify="flex-start"
                            alignItems="flex-end"
                            className="SectionTheme-Logo-Container">
                                <Grid item xs={12} sm={2}>
                                {
                                    this.props.profileImage !== null ?
                                    <img
                                    src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                                    class="SectionTheme-Logo"
                                    alt={`Company Logo`}/> :
                                    <div class="SectionTheme-Logo-Default"></div>
                                }
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={9}>
                                <Typography variant="h4"
                                className="TOIntro-Text">
                                    <Box fontWeight="600">
                                        Great! Please wait while we put together your itinerary.
                                    </Box>
                                    <CircularProgress />
                                </Typography>
                            </Grid>

                        </Grid>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}


// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        profileImage: state.profileImage,
        selectedExperiences: state.selectedExperiences,
        selectedExperienceAddOns: state.selectedExperienceAddOns,
        ItineraryData: state.data,
        fullName: state.fullName,
        numberOfTravelers: state.numberOfTravelers,
        adventureStartDate: state.adventureStartDate,
        approximateDays: state.approximateDays,
        approximateDayVariance: state.approximateDayVariance,
        luxuryLevel: state.luxuryLevel,
        CompanyName: state.tourOperatorCompanyName,
        OperatorName: state.tourOperatorName,

    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setItineraryData: (data) => {
            dispatch(setItineraryData(data))
        }
    }
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(Itinerary);
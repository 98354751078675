import React from 'react';
import './LocationMap.css';

//Leaflet
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

//Material UI
import Grid from '@material-ui/core/Grid';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

//MAPS
// https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
// https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png

class LocationMap extends React.Component {


  render() {
    return (
      <div>
        <Grid container
            justify="center"
            alignItems="center">
        </Grid>

                <Grid container
                justify="center"
                alignItems="center">
                    <Grid item container xs={12}>
                    <Map className="itnerary-map-map"
                    center={[this.props.latitude, this.props.longitude]}
                    zoom={7}>
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        />
                        <Marker position={[this.props.latitude, this.props.longitude]}>
                            <Popup>{this.props.locationTitle}</Popup>
                        </Marker>
                    </Map>
                    </Grid>
                </Grid>

    </div>
    )
  }
}

export default LocationMap;
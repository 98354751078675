import {
    ADD_OPERATOR_DETAILS,
    ADD_NUMBER_OF_TRAVELERS,
    ADD_SECTION,
    REMOVE_SECTION,
    ADD_EXPERIENCE,
    REMOVE_EXPERIENCE,
    ADD_EXPERIENCE_ADD_ON,
    REMOVE_EXPERIENCE_ADD_ON,
    SET_TOID,
    RESET_STORAGE,
    SET_ITINERARY_DATA,
    SET_LUXURY_LEVEL,
    SET_NUMBER_DAYS,
    ADD_CONSUMER_NAME,
    ADD_TRAVEL_DATE
} from './actions';

export const setOperatorDetails = (tourOperatorCompanyName, tourOperatorName, profileImage, email) => {
    return {
        type: ADD_OPERATOR_DETAILS,
        operatorName: tourOperatorName,
        companyName: tourOperatorCompanyName,
        profileImage: profileImage,
        email: email
    }
}

export const addSections = (section) => {
    return {
        type: ADD_SECTION,
        section: section
    }
}

export const removeSection = (section) => {
    return {
        type: REMOVE_SECTION,
        section: section
    }
}

export const addExperience = (experience) => {
    return {
        type: ADD_EXPERIENCE,
        experience: experience
    }
}

export const removeExperience = (experience) => {
    return {
        type: REMOVE_EXPERIENCE,
        experience: experience
    }
}

export const addExperienceAddOn = (addOn) => {
    return {
        type: ADD_EXPERIENCE_ADD_ON,
        addOn: addOn
    }
}

export const removeExperienceAddOn = (addOn) => {
    return {
        type: REMOVE_EXPERIENCE_ADD_ON,
        addOn: addOn
    }
}

export const setTOID = (toid) => {
    return {
        type: SET_TOID,
        TOID: toid
    }
}

export const resetStorage = () => {
    return {
        type: RESET_STORAGE
    }
}

export const setItineraryData = (data) => {
    return {
        type: SET_ITINERARY_DATA,
        data: data
    }
};

export const setNumberTravelers = (numberOfTravelers) => {
        return{
            type: ADD_NUMBER_OF_TRAVELERS,
            numOfTravelers: numberOfTravelers

        }
};

export const setLuxuryLevel = (luxuryLevel) => {
        return{
            type: SET_LUXURY_LEVEL,
            luxLevel: luxuryLevel
        }
};

export const setNumberDays = (approximateDays,approximateDaysVariance) => {
        return{
            type: SET_NUMBER_DAYS,
            approxDays: approximateDays,
            approxVariance: approximateDaysVariance
        }
}

export const setConsumerName = (fullName) => {
    return{
        type: ADD_CONSUMER_NAME,
        consumerName:fullName
    }
};

export const setTravelDate = (adventureStartDate) => {
    return{
        type: ADD_TRAVEL_DATE,
        travelDate: adventureStartDate
    }
}

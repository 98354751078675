import React from 'react';
import './SectionThemeCard.css';

// Import Material UI Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Redux
import { connect } from 'react-redux';
import { addSections, removeSection } from '../../../actions/PackageActions';

// Google Analytics
import ReactGA from 'react-ga';

class SectionThemeCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sectionSelected: false,
            open: false,
            name: this.props.Name,
            description: this.props.Description
        }
    }

    componentDidMount() {
        if (this.props.selectedSections.findIndex(x => x.SID === this.props.SID) !== -1) {
            this.setState({
                sectionSelected: true
            })
        }
    }

    handleDialogOpen = () => {
        ReactGA.event({
            category: `${this.props.TOID} - Theme Button Click`,
            action: `${this.props.TOID} - ${this.props.SID} - Theme Description Clicked`,
            label: `${this.props.TourOperatorName} - ${this.props.SID} - Theme Description`
        })

        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    async handleSelect(SectionObject) {
        ReactGA.event({
            category: `${this.props.TOID} - Theme Button Click`,
            action: `${this.props.TOID} - ${this.props.SID} - Theme Selected`,
            label: `${this.props.TourOperatorName} - ${this.props.SID} - Theme Selected`
        })

        this.setState({
            sectionSelected: true
        });
        await this.props.addSections(SectionObject);
    }

    async handleRemove(SectionObject) {
        ReactGA.event({
            category: `${this.props.TOID} - Theme Button Click`,
            action: `${this.props.TOID} - ${this.props.SID} - Theme Removed`,
            label: `${this.props.TourOperatorName} - ${this.props.SID} - Theme Removed`
        })

        this.setState({
            sectionSelected: false
        });
        await this.props.removeSection(SectionObject);
    }


    render() {
        return (
            <div>
            <Fade in={true} timeout={800}>
            <div class="SectionThemeCard-Padding">
                {
                    this.state.sectionSelected
                    ?
                    <Card className="Home-Card-Spacing" gutterBottom>
                    <CardContent className="Home-Card-Div-Heading-Selected">
                        <Typography variant="h4"
                        className="Home-Card-Font-Heading">
                            {this.props.Name}
                        </Typography>
                    </CardContent>
                    </Card>
                    :
                    <Card className="Home-Card-Spacing" gutterBottom>
                    <CardContent className="Home-Card-Div-Heading">
                        <Typography variant="h4"
                        className="Home-Card-Font-Heading">
                            {this.props.Name}
                        </Typography>
                    </CardContent>
                    </Card>
                }

            <Grid container
            justify="space-between">
                <Grid item>
                    <Button variant="contained" color="primary"
                    onClick={this.handleDialogOpen}>
                    Description
                    </Button>
                </Grid>
                <Grid item>
                    {
                        this.state.sectionSelected ?
                        <Button variant="contained" color="primary"
                        onClick={() => this.handleRemove(this.props.SectionObject)}>
                            Remove
                        </Button>
                        :
                        <Button variant="contained" color="primary"
                        onClick={() => this.handleSelect(this.props.SectionObject)}>
                            Select
                        </Button>
                    }

                </Grid>
            </Grid>

            </div>
            </Fade>

            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {this.state.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            </div>
        )
    }
}

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        selectedSections: state.selectedSections,
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        addSections: (section) => {
            dispatch(addSections(section))
        },
        removeSection: (section) => {
            dispatch(removeSection(section))
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(SectionThemeCard);
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

// Redux Store Imports
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import getStoreAndPersistor from './store';

// React Router Imports
import { Route } from 'react-router-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

// Import Other Components
import TOIntro from './components/TOIntro/TOIntro';
import Travellers from './components/NumOfTravellers/Travellers';
import SectionTheme from './components/SectionTheme/SectionTheme';
import Experience from './components/Experience/Experience';
import AddOns from './components/AddOns/AddOns';
import Itinerary from './components/Itinerary/Itinerary';
import ItineraryPage from './components/ItineraryPage/ItineraryPage';
import LuxuryLevel from './components/LuxuryLevel/LuxuryLevel';
import NumberOfDays from './components/NumberOfDays/NumberOfDays'
import ConsumerName from './components/ConsumerName/Name';
import TravelDate from './components/TravelDate/TravelDate';
import Review from './components/Review/Review';
import Itineraries from './components/Itineraries/Itineraries';

// Get Theme
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme';

// Import Analytics
import Analytics from 'react-router-ga';

// Import React and Initialize
import ReactGA from 'react-ga';
ReactGA.initialize('UA-156135079-3');

// Get the Store and Persistor
const { store, persistor } = getStoreAndPersistor();
persistor.flush();

export default class AppRouter extends React.Component {

  render() {
    return (
      <Router>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Analytics id="UA-156135079-3" >
              <Switch>
                <Route exact path="/" component={TOIntro} />
                <Route exact path="/:TourOperatorName/:TOID" render={(props) => <TOIntro {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/travel-date" render={(props) => <TravelDate {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/travellers" render={(props) => <Travellers {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/themes" render={(props) => <SectionTheme {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/experiences" render={(props) => <Experience {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/experience-add-ons" render={(props) => <AddOns {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/itinerary" render={(props) => <Itinerary {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/your-adventure/:ItineraryID" render={(props) => <ItineraryPage {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/luxury" render={(props) => <LuxuryLevel {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/number-of-days" render={(props) => <NumberOfDays {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/name" render={(props) => <ConsumerName {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/review" render={(props)=> <Review {...props}/>}/>
                <Route exact path="/:TourOperatorName/:TOID/itineraries" render={(props) => <Itineraries {...props}/>}/>
              </Switch>
              </Analytics>
            </PersistGate>
        </Provider>
      </MuiThemeProvider>
    </Router>
    )
  }
}


ReactDOM.render(
  <AppRouter />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

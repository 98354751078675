import React from 'react';
import axios from 'axios';

// Import Config URL
import URL from '../../config';
import IMAGE_URL from '../../image-config';

// Import Other Components
import AddOnSection from './AddOnSection/AddOnSection';

// Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Redux
import { connect } from 'react-redux';

// React Router Redirect
import { Link } from 'react-router-dom';

// Google Analytics
import ReactGA from 'react-ga';

class AddOns extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            TOID: this.props.match.params.TOID,
            TOName: this.props.match.params.TourOperatorName,
            open: false,
            paid: false
        }
    }

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Add Ons Page View`,
            label: `${this.props.match.params.TourOperatorName} - Add Ons`
        })

        axios.post(`${URL}/api/v1/tour-operator/get-operator-form-data`, {
            TOID: this.state.TOID
        }, {})
        .then((response) => {
            if(response.data.length > 0) {
                this.setState({
                    paid: true
                })
            }
        })
    }

    handleContinueDialog = () => {
        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.mascotName !== null && this.state.companyName !== null && this.state.paid?
                    <div class="SectionTheme-Parent-Div">
                        <Grid item container xs={12}
                        justify="flex-start"
                        alignItems="center"
                        direction="column"
                        className="SectionTheme-Grid-Container">

                            <Grid item container xs={11}
                            justify="flex-start"
                            alignItems="flex-end"
                            className="SectionTheme-Logo-Container">
                                <Grid item xs={12} sm={2}>
                                {
                                    this.props.profileImage !== null ?
                                    <img
                                    src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                                    class="SectionTheme-Logo"
                                    alt={`Company Logo`}/> :
                                    <div class="SectionTheme-Logo-Default"></div>
                                }
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Typography variant="h4"
                                    className="TOIntro-Text">
                                        <Box fontWeight="600">
                                            Awesome, here are some add-ons for the experiences you selected. Choose the ones you want apart of your journey.
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>


                            <Grid item container
                            direction="column"
                            alignItems="center"
                            justify="center">
                                {
                                    this.props.selectedExperiences.map((experience) => {
                                        return (
                                            <AddOnSection
                                            Experience = {experience}
                                            TOID={this.state.TOID}
                                            TourOperatorName={this.props.match.params.TourOperatorName}/>
                                        )
                                    })
                                }

                            </Grid>


                            <Grid item container xs={11} sm={8}
                            justify="space-between"
                            alignItems="center">
                                <Grid item>
                                    <Button
                                    color="secondary"
                                    variant="contained"
                                    component={Link}
                                    to={`/${this.state.TOName}/${this.state.TOID}/experiences`}
                                    size="large">
                                        <ArrowBack /> Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {
                                        this.props.selectedExperienceAddOns.length > 0 ?
                                        <Button
                                        color="secondary"
                                        variant="contained"
                                        component={Link}
                                        to={`/${this.state.TOName}/${this.state.TOID}/review`}
                                        size="large">
                                            Continue <ArrowForward />
                                        </Button>
                                        :
                                        <Button variant="contained" color="secondary"
                                        size="large"
                                        onClick={this.handleContinueDialog}>
                                            Continue <ArrowForward />
                                        </Button>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                    :
                    null
                }

                <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Continue without add-ons?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to continue without any of these experience add-ons?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.handleClose}
                        size="small">
                            Cancel
                        </Button>
                        <Button
                        color="secondary"
                        variant="outlined"
                        component={Link}
                        to={`/${this.state.TOName}/${this.state.TOID}/review`}
                        size="small">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}


// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        profileImage: state.profileImage,
        selectedExperiences: state.selectedExperiences,
        selectedExperienceAddOns: state.selectedExperienceAddOns
    }
}

export default connect(
    mapStateToProps, null
)(AddOns);
import React from 'react';
import axios from 'axios';
import './Experience.css';

// Import Config URL
import URL from '../../config';
import IMAGE_URL from '../../image-config';

// Import Other Components
import ExperienceSection from './ExperienceSection/ExperienceSection';

// Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';

// Redux
import { connect } from 'react-redux';
import { addSections } from '../../actions/PackageActions';

// React Router Redirect
import { Link } from 'react-router-dom';

// Google Analytics
import ReactGA from 'react-ga';

/*
    <Grid item container xs={11} sm={8}
    justify="space-between"
    alignItems="flex-start">
        {
            this.state.sections.map((sectionObject) => {
                return (
                    <Grid item xs={12} sm={5}>
                        <SectionThemeCard
                        SID={sectionObject.SID}
                        Name={sectionObject.Name}
                        Description={sectionObject.Description}
                        />
                    </Grid>
                )
            })
        }
    </Grid>
*/

class Experience extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            TOID: this.props.match.params.TOID,
            TOName: this.props.match.params.TourOperatorName,
            name: '',
            paid: false
        }
    }

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Experience Page View`,
            label: `${this.props.match.params.TourOperatorName} - Experience`
        });

        axios.post(`${URL}/api/v1/tour-operator/get-operator-form-data`, {
            TOID: this.state.TOID
        }, {})
        .then((response) => {
            if(response.data.length > 0) {
                this.setState({
                    paid: true
                })
            }
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.mascotName !== null && this.state.companyName !== null && this.state.paid ?
                    <div class="SectionTheme-Parent-Div">
                        <Grid item container xs={12}
                        justify="flex-start"
                        alignItems="center"
                        direction="column"
                        className="SectionTheme-Grid-Container">

                                <Grid item container xs={11}
                                justify="flex-start"
                                alignItems="flex-end"
                                className="SectionTheme-Logo-Container">
                                    <Grid item xs={12} sm={2}>
                                    {
                                        this.props.profileImage !== null ?
                                        <img
                                        src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                                        class="SectionTheme-Logo"
                                        alt={`Company Logo`}/> :
                                        <div class="SectionTheme-Logo-Default"></div>
                                    }
                                    </Grid>

                                    <Grid item xs={12} sm={9}>
                                        <Typography variant="h4"
                                        className="TOIntro-Text">
                                            <Box fontWeight="600">
                                                Great selection! Here are some experiences we have available for the themes you've selected. Select the ones you want.
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>


                            <Grid item container
                            direction="column"
                            alignItems="center"
                            justify="center">
                                {
                                    this.props.selectedSections.map((section) => {
                                        return (
                                            <ExperienceSection
                                            Section = {section}
                                            TOID={this.state.TOID}
                                            TourOperatorName={this.props.match.params.TourOperatorName}/>
                                        )
                                    })
                                }

                            </Grid>

                            <Grid item container xs={11} sm={8}
                            justify="space-between"
                            alignItems="center">
                                <Grid item>
                                    <Button
                                    color="secondary"
                                    variant="contained"
                                    component={Link}
                                    to={`/${this.state.TOName}/${this.state.TOID}/themes`}
                                    size="large">
                                        <ArrowBack /> Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {
                                        this.props.selectedExperiences.length > 0 ?
                                        <Button
                                        color="secondary"
                                        variant="contained"
                                        component={Link}
                                        to={`/${this.state.TOName}/${this.state.TOID}/experience-add-ons`}
                                        size="large">
                                            Continue <ArrowForward />
                                        </Button>
                                        :
                                        <Button variant="contained" color="secondary"
                                        size="large" disabled>
                                            Continue <ArrowForward />
                                        </Button>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}


// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        profileImage: state.profileImage,
        selectedExperiences: state.selectedExperiences,
        selectedSections: state.selectedSections
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        addSections: (section) => {
            dispatch(addSections(section))
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Experience);
import React from 'react';
import './ParagraphText.css';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

class ParagraphText extends React.Component {
  render() {
    return (
        <div class="paragraph-text-outer-div">
        <Grid container
        direction="row"
        justify="center"
        alignItems="center">
            <Grid item container xs={11} sm={11}
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item xs={11} sm={6}>
                <Typography variant="h6" id="HeaderText">{this.props.title}</Typography>
            </Grid>
            <Grid item xs={11} sm={6}>
                <Typography variant="subtitle1" id="DescriptionText">
                {this.props.description}
                On this trip you'll get to experience the following: {this.props.experiences}.
                {
                  this.props.addOns.length > 0 ?
                  ` You'll also get to do the following add-ons for the experience(s) you selected: ${this.props.addOns}`
                  :
                  null
                }

                </Typography>
            </Grid>
            </Grid>
        </Grid>
        </div>
    );
  }
}

export default ParagraphText;
import React from "react";
import "./ItineraryPage.css";
import axios from "axios";

// Import Components
import ItineraryDayPlan from "./ItineraryDayPlan/ItineraryDayPlan";
import ItineraryDisplaySection from "./ItineraryDisplaySection/ItineraryDisplaySection";
import ItineraryOverviewSection from "./ItineraryOverviewSection/ItineraryOverviewSection";
import ParagraphText from "./ParagraphText/ParagraphText";
import ItineraryMap from "./ItineraryMap/ItineraryMap";
import ItineraryLocalOperator from "./ItineraryLocalOperator/ItineraryLocalOperator";
import { setLuxuryLevel, setNumberTravelers, setConsumerName,
    addExperienceAddOn, addExperience, setNumberDays,
    setTravelDate, resetStorage, setOperatorDetails } from '../../actions/PackageActions';


import { Redirect } from "react-router-dom";

import reduce from "lodash/reduce";
import sampleSize from "lodash/sampleSize";

// Import Config URL
import URL from "../../config";

// Redux
import { connect } from "react-redux";

class ItineraryPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            TOID: this.props.match.params.TOID,
            TOName: this.props.match.params.TourOperatorName,
            CompanyName: '',
            OperatorName: '',
            ProfileImage: '',
            paid: null,
            addOns: "",
            activityLevel: "",
            locations: [],
            experiences: "",
            email: null,
            itinerary: "",
            displayImages: [],
            LuxuryLevel: '',
            NumberOfTravellers: 0,
            Fullname: '',
            SelectedExperienceAddOns: [],
            SelectedExperiences: [],
            ApproximateDays: '',
            ApproximateDaysVariance: 0,
            StartDate: '',

        };

        this.HandleAllData = this.HandleAllData.bind(this);
        this.checkTOIDinHREF = this.checkTOIDinHREF.bind(this);
        this.checkAndSetOperatorDetails = this.checkAndSetOperatorDetails.bind(this);
    }

    async checkTOIDinHREF() {
        let href = String(window.location.href);
        let firstIndex = String(href).indexOf(String(URL));
        let lastIndex = firstIndex + String(URL).length;
        let params = href.substring(lastIndex+2, href.length);
        let toidIndex = params.indexOf('/');
        let toid = params.substring(toidIndex+1, params.length);
        toidIndex = toid.indexOf('/');
        let id = toid;
        if (toidIndex !== -1) {
            id = toid.substring(0, toidIndex);
        }
        if (this.props.ReduxTOID !== id) {
            // Dispatch rule to Reset the Local Storage
            await this.props.resetStorage();
            await this.props.setTOID(id);
        }
    }

    HandleAllData = async () => {

        //checking if we need to get info from MongoDatabase
        if (
            this.props.location.state === undefined ||
            this.props.location.state === null
        ) {

            // Reset the Redux Store
            await this.props.resetStorage();

            // Axios Post Request to get the Itinerary Data
            axios.post(`${URL}/api/v1/itinerary/get-itinerary`,
                {
                    UUID: this.props.match.params.ItineraryID,
                },
                {}
            )
            .then(async (response) => {

                // Get the Response Data
                this.props.location.state = response.data;

                // Set the State Properties
                await this.setState({
                    TOID: this.props.match.params.TOID,
                    TOName: this.props.match.params.TourOperatorName,
                    addOns: this.props.location.state.AddonList,
                    activityLevel: this.props.location.state.OverallActivityLevel,
                    experiences: this.props.location.state.ExperienceList,
                    itinerary: this.props.location.state,
                    OperatorName: this.props.location.state.OperatorName,
                    ProfileImage: this.props.location.state.ProfileImage,
                    LuxuryLevel: this.props.location.state.LuxuryLevel,
                    NumberOfTravellers: this.props.location.state.NumberOfTravellers,
                    Fullname: this.props.location.state.FullName,
                    SelectedExperienceAddOns: this.props.location.state.SelectedExperienceAddOns,
                    SelectedExperiences: this.props.location.state.SelectedExperiences,
                    ApproximateDays: this.props.location.state.ApproximateDays,
                    ApproximateDaysVariance: this.props.location.state.ApproximateDaysVariance,
                    StartDate: new Date(this.props.location.state.StartDate),

                });

                // Check and Set the Operator Details
                await this.checkAndSetOperatorDetails();

                // Set the state of the Redux Store
                await this.props.setLuxuryLevel(this.state.LuxuryLevel);
                await this.props.setNumberTravelers(this.state.NumberOfTravellers);
                await this.props.setConsumerName(this.state.Fullname);
                await this.props.setNumberDays(this.state.ApproximateDays, this.state.ApproximateDaysVariance);
                await this.props.setTravelDate(this.state.StartDate);

                // Set the Selected Experiences in the Redux Store
                for(let i = 0; i < this.state.SelectedExperienceAddOns.length; i++){
                    await this.props.addExperienceAddOn(this.state.SelectedExperienceAddOns[i]);
                }

                // Set the Selected Add Ons in the Redux Store
                for(let j = 0; j < this.state.SelectedExperiences.length; j++){
                    await this.props.addExperience(this.state.SelectedExperiences[j]);
                }


            });
        } else {
            this.setState({
                addOns: this.props.location.state.addOns,
                activityLevel: this.props.location.state.activityLevel,
                experiences: this.props.location.state.experiences,
                itinerary: this.props.location.state.itinerary,
                CompanyName: this.props.CompanyName,
                OperatorName: this.props.OperatorName,
            });

            await this.checkAndSetOperatorDetails();
        }

    }

    componentDidMount() {
        this.HandleAllData();
    }


    checkAndSetOperatorDetails = () => {
        // Get the Operator Form Data and Set Regardless if Already in the Storage
        // Axios Post Request to Check if Paid or Not
        axios.post( `${URL}/api/v1/tour-operator/get-operator-form-data`,
            {
                TOID: this.props.match.params.TOID,
            }
        )
        .then((response) => {

            if (response.data.length > 0) {

                // Redux function called to set the state for the Company Name and Operator Name
                this.props.setOperatorDetails(response.data[0].CompanyName, response.data[0].FirstName, response.data[0].ProfileImage, response.data[0].Email);


                // Format the Locations
                let locationData = reduce(
                    this.state.itinerary.Locations,
                    function (result, loc) {
                        result = [
                            ...result,
                            {
                                latitude: loc.Latitude,
                                longitude: loc.Longitude,
                                title: loc.Title,
                                description: loc.Description,
                            },
                        ];
                        return result;
                    },
                    []
                );

                // Set the Display Images (Random Sample)
                let displayImages = reduce(
                    this.state.itinerary.Itinerary,
                    function (result, day) {
                        if (day.image !== null) {
                            if (
                                day.image.Filename !== "arrival.png" &&
                                day.image.Filename !== "last-transfer.png" &&
                                day.image.Filename !== "departure.png"
                            ) {
                                result = [...result, day.image];
                            }
                        }
                        return result;
                    },
                    []
                );
                if (displayImages.length > 4) {
                    displayImages = sampleSize(displayImages, 4);
                }

                this.setState({
                    OperatorName: response.data[0].FirstName,
                    CompanyName: response.data[0].CompanyName || "CloudSafari Inc.",
                    ProfileImage: response.data[0].ProfileImage,
                    email: response.data[0].Email,
                    paid: true,
                    displayImages: displayImages,
                    locations: locationData,
                })

            } else {
                this.setState({
                    paid: false,
                });
            }

        });
    }

    render() {
        return (
            <div>
                {this.state.paid === false ? (
                    <Redirect to={`/${this.state.TOName}/${this.state.TOID}`} />
                ) : null}
                {

                    this.state.paid && this.state.locations.length > 0 ? (
                        <div>
                            <ItineraryDisplaySection
                                DisplayImages={this.state.displayImages}
                            />

                            <ItineraryOverviewSection
                                overview={this.state.itinerary}
                                itineraryLength={this.state.itinerary.Itinerary.length}
                                companyName={this.state.CompanyName}
                                activityLevel={this.state.activityLevel}
                                email={this.state.email}
                                itinerary={this.state.itinerary.Itinerary}
                                TOID={this.state.TOID}
                            />

                            <ParagraphText
                                title="the adventure overview"
                                description={`Exploring Tanzania with the best experiences chosen by you and designed by us. `}
                                experiences={this.state.experiences}
                                addOns={this.state.addOns}
                            />

                            <ItineraryDayPlan itinerary={this.state.itinerary.Itinerary} />

                            <ItineraryMap locations={this.state.locations} />

                            <ItineraryLocalOperator
                                email={this.state.email}
                                itinerary={this.state.itinerary.Itinerary}
                                TOID={this.state.TOID}
                                TourCompanyName={this.state.CompanyName}
                                OperatorIdentity={this.state.OperatorName}
                                ProfilePicture={this.state.ProfileImage}
                            />
                        </div>
                    ) : null

                }
            </div>
        );
    }
}

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        ItineraryData: state.data,
        CompanyName: state.tourOperatorCompanyName,
        selectedExperiences: state.selectedExperiences,
        selectedAddOns: state.selectedExperienceAddOns,
        OperatorName: state.tourOperatorName,
    };
};

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setLuxuryLevel: (luxLevel) => {
            dispatch(setLuxuryLevel(luxLevel))
        },
        setNumberTravelers: (numOfTravelers) => {
            dispatch(setNumberTravelers(numOfTravelers))
        },
        setConsumerName: (consumerName) => {
            dispatch(setConsumerName(consumerName))
        },
        addExperienceAddOn: (addOn) => {
            dispatch(addExperienceAddOn(addOn))
        },
        addExperience: (exp) => {
            dispatch(addExperience(exp))
        },
        setNumberDays: (approxDays,approxVariance) => {
            dispatch(setNumberDays(approxDays,approxVariance))
        },
        setTravelDate: (travelDate) => {
            dispatch(setTravelDate(travelDate))
        },
        setOperatorDetails: (companyName, operatorName, profileImage, email) => {
            dispatch(setOperatorDetails(companyName, operatorName, profileImage, email))
        },
        resetStorage: () => {
            dispatch(resetStorage())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryPage);

import React from 'react';
import './ItineraryDayPlan.css';

//Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

// Image URL
import IMAGE_URL from '../../../image-config';

class ItineraryDayPlan extends React.Component {
  render() {
    return (
      <div>
        <Grid container
        justify="center"
        alignItems="center">
          <Grid item container xs={11}
          justify="flex-start"
          alignItems="flex-start">
          <Typography variant="h5"><strong>your adventure itinerary</strong></Typography>
          </Grid>
        </Grid>

        <Grid container
        direction="row"
        alignItems="center"
        justify="center">

        {this.props.itinerary.map(day => {
            return (
                <Grid item container xs={11}
                direction="row"
                alignItems="center"
                justify="center">
                    <Hidden xsDown>
                    <Grid item container xs={12} sm={6}
                    direction="column"
                    alignItems="flex-end"
                    justify="flex-start">
                        {
                            day.image ?
                            <div class="itinerary-day-plan-experience">
                                <img src={`${IMAGE_URL}/form/${day.image.Filename}`}
                                class="loading"
                                alt={`${day.title} - ${day.image.Title}`}/>
                            </div>
                                :
                            <div class="itinerary-day-plan-experience">
                                <div class="itinerary-day-plan-default" />
                                <div class="itinerary-day-plan-centered">
                                    <Typography variant="h4">{day.title}</Typography>
                                </div>
                            </div>
                        }

                    </Grid>
                    </Hidden>
                    <Hidden smUp>
                    <Grid item container xs={11} sm={6}
                    direction="column"
                    justify="center"
                    alignItems="center">
                        {
                            day.image ?
                            <div class="itinerary-day-plan-experience">
                                <img src={`${IMAGE_URL}/form/${day.image.Filename}`}
                                class="loading"
                                alt={`${day.title} - ${day.image.Title}`}/>
                            </div>
                                :
                            <div class="itinerary-day-plan-experience">
                                <div class="itinerary-day-plan-default" />
                                <div class="itinerary-day-plan-centered">
                                    <Typography variant="h4">{day.title}</Typography>
                                </div>
                            </div>
                        }
                    </Grid>
                    </Hidden>
                    <Grid item container xs={11} sm={6}
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start">
                    <Grid item>
                    <Typography variant="h6"><strong>{day.title}</strong></Typography>
                    </Grid>
                    <Grid item>
                    <Typography variant="subtitle1">
                        {day.description}
                    </Typography>
                    </Grid>
                    </Grid>
                </Grid>
            );
            })
        }

        </Grid>

      </div>
    );
  }
}

export default ItineraryDayPlan;
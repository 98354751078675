// Import Actions (Later)
import {
    ADD_OPERATOR_DETAILS,
    ADD_NUMBER_OF_TRAVELERS,
    ADD_SECTION,
    REMOVE_SECTION,
    ADD_EXPERIENCE,
    REMOVE_EXPERIENCE,
    ADD_EXPERIENCE_ADD_ON,
    REMOVE_EXPERIENCE_ADD_ON,
    SET_TOID,
    RESET_STORAGE,
    SET_ITINERARY_DATA,
    SET_LUXURY_LEVEL,
    SET_NUMBER_DAYS,
    ADD_CONSUMER_NAME,
    ADD_TRAVEL_DATE
} from '../actions/actions';

const initialState = {
    tourOperatorName: null,
    tourOperatorCompanyName: null,
    profileImage: null || 'default-image.jpg',
    fullName: "User",
    numberOfTravelers: 2,
    adventureStartDate: new Date(),
    approximateDays: 10,
    approximateDayVariance: 0,
    luxuryLevel: null,
    selectedSections: [],
    selectedExperiences: [],
    selectedExperienceAddOns: [],
    TOID: null,
    data: null,
    TOEmail: null
}

function reducer(state = initialState, action) {
    if (action.type === ADD_OPERATOR_DETAILS) {
        return {
            ...state,
            tourOperatorName: action.operatorName,
            tourOperatorCompanyName: action.companyName,
            profileImage: action.profileImage,
            TOEmail: action.email
        }
    }

    if( action.type === ADD_NUMBER_OF_TRAVELERS) {
        return {
            ...state,
            numberOfTravelers:action.numOfTravelers
        }
    }

    if (action.type === ADD_SECTION) {
        return {
            ...state,
            selectedSections: [...state.selectedSections, action.section]
        }
    }

    if (action.type === REMOVE_SECTION) {

        // Find Section Index
        var sectionIndex = state.selectedSections.findIndex(x => x.SID === action.section.SID);

        return {
            ...state,
            selectedSections: [...state.selectedSections.slice(0, sectionIndex), ...state.selectedSections.slice(sectionIndex+1)]
        }
    }

    if (action.type === ADD_EXPERIENCE) {
      return {
        ...state,
        selectedExperiences: [...state.selectedExperiences, action.experience]
      }
    }

    if (action.type === REMOVE_EXPERIENCE) {

      // Find the Experience Index
      var experienceIndex = state.selectedExperiences.findIndex(x => x.EID === action.experience.EID);

      return {
        ...state,
        selectedExperiences: [...state.selectedExperiences.slice(0, experienceIndex), ...state.selectedExperiences.slice(experienceIndex+1)]
      }
    }

    if (action.type === ADD_EXPERIENCE_ADD_ON) {
      return {
        ...state,
        selectedExperienceAddOns: [...state.selectedExperienceAddOns, action.addOn]
      }
    }

    if (action.type === REMOVE_EXPERIENCE_ADD_ON) {
      var experienceAddOnIndex = state.selectedExperienceAddOns.findIndex(x => x.AIID === action.addOn.AIID);
      return {
        ...state,
        selectedExperienceAddOns: [...state.selectedExperienceAddOns.slice(0, experienceAddOnIndex), ...state.selectedExperienceAddOns.slice(experienceAddOnIndex+1)]
      }
    }

    if (action.type === SET_TOID) {
      return {
        ...state,
        TOID: action.TOID
      }
    }

    if (action.type === RESET_STORAGE) {
      return {
        ...initialState
      }
    }

    if (action.type === SET_ITINERARY_DATA) {
      return {
        ...state,
        data: action.data
      }
    }

    if (action.type === SET_LUXURY_LEVEL) {
      return {
        ...state,
        luxuryLevel: action.luxLevel
      }
    }

    if (action.type === SET_NUMBER_DAYS) {
      return {
        ...state,
        approximateDays:action.approxDays,
        approximateDayVariance: action.approxVariance
      }
    }

    if (action.type === ADD_CONSUMER_NAME) {
      return{
        ...state,
        fullName:action.consumerName
      }
    }

    if (action.type === ADD_TRAVEL_DATE) {
      return{
        ...state,
        adventureStartDate:action.travelDate
      }
    }

    return state;
}

export default reducer;

/*
 if (action.type === REMOVE_PACKAGE) {
    // Find the Index of the Package in the Array (have to look through each object)
    //var packageIndex = state.selectedPackages.indexOf(action.package_id);
    var packageIndex = state.selectedPackages.findIndex(x => x.ExperienceID === action.experience.ExperienceID);

    // Remove the Package
    return {
      ...state,
      selectedPackages: [...state.selectedPackages.slice(0, packageIndex), ...state.selectedPackages.slice(packageIndex+1)]
    }
  }

  if (action.type === ADD_BASIC_DETAILS) {
    return {
      ...state,
      numberAdults: action.adults,
      numberChildren: action.children,
      adventureStartDate: action.travelDate,
      budget: action.budget,
      currency: action.currency,
      email: action.email
    }
  }

  if (action.type === ADD_COUNTRY) {
    return {
      ...state,
      selectedCountries: [...state.selectedCountries, action.countryId]
    }
  }

  //Potential bug in the removal - DOUBLE CHECK
  if (action.type === REMOVE_COUNTRY) {
    var countryIndex = state.selectedCountries.findIndex(x => x === action.countryId);
    return {
      ...state,
      selectedCountries: [...state.selectedCountries.slice(0, countryIndex), ...state.selectedCountries.slice(countryIndex+1)]
    }
  }

  if (action.type === ADD_SECTION) {
    return {
      ...state,
      selectedSections: [...state.selectedSections, action.countrySectionId]
    }
  }

  if (action.type === REMOVE_SECTION) {
    var sectionIndex = state.selectedSections.findIndex(x => x === action.countrySectionId);
    return {
      ...state,
      selectedSections: [...state.selectedSections.slice(0, sectionIndex), ...state.selectedSections.slice(sectionIndex+1)]
    }
  }
*/
import React from 'react';

// Import Material UI
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';


// Lodash reduce function
import reduce from 'lodash/reduce';

import { Link } from 'react-router-dom';

// Google Analytics
import ReactGA from 'react-ga';

class ItineraryCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            experiences: '',
            addOns: '',
            activityLevel: 0
        }

        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    componentDidMount() {
        // Use Lodash to get the state values
        let activityLevel = 0;
        let experiences = reduce(this.props.itinerary.Experiences, function(result, exp) {
            if (exp.ActivityLevel > activityLevel) {
                activityLevel = exp.ActivityLevel;
            }
            result = [...result, exp.Title]
            return result
        }, []);

        let addOnObjects = reduce(this.props.itinerary.Experiences, function(result, exp) {
            result = [...result, ...exp.AddOns]
            return result;
        }, []);

        let addOns = reduce(addOnObjects, function(result, obj) {
            result = [...result, obj.Title];
            return result;
        }, []);

        this.setState({
            experiences: experiences.join(", "),
            activityLevel: activityLevel,
            addOns: addOns.join(", ")
        })
    }

    handleButtonClick = (event) => {
        if (this.props.fullLength) {
            ReactGA.event({
                category: `${this.props.TOID} - View Itinerary Button Click`,
                action: `${this.props.TOID} - Full Itinerary Clicked`,
                label: `${this.props.TOName} - Full Itinerary`
            })
        } else {
            ReactGA.event({
                category: `${this.props.TOID} - View Itinerary Button Click`,
                action: `${this.props.TOID} - Reduced Itinerary Clicked`,
                label: `${this.props.TOName} - Reduced Itinerary`
            })
        }

    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Typography variant="h6">
                        {this.props.itinerary.Itinerary.length}-day Experience
                    </Typography>
                    <Typography variant="subtitle1">
                        <Box fontWeight={700}>Experiences: </Box>
                        {this.state.experiences}
                    </Typography>
                    {
                        String(this.state.addOns).length > 0 ?
                        <Typography variant="subtitle1">
                            <Box fontWeight={700}>Add Ons: </Box>
                            {this.state.addOns}
                        </Typography>
                        :
                        null
                    }
                    <Typography variant="subtitle1">
                        <Box fontWeight={700}>ActivityLevel: </Box>
                        {this.state.activityLevel}/5
                    </Typography>
                </CardContent>
                <CardActions>
                    <Grid container
                    justify="flex-end">
                        <Grid item>
                        <Button
                        color="secondary"
                        variant="contained"
                        onClick={this.handleButtonClick}
                        component={Link}
                        to={{
                            pathname: `/${this.props.TOName}/${this.props.TOID}/your-adventure/${this.props.itinerary._id}`,
                            state: {
                                itinerary: this.props.itinerary,
                                activityLevel: this.state.activityLevel,
                                addOns: this.state.addOns,
                                experiences: this.state.experiences
                            }
                        }}
                        size="large">
                            View Itinerary
                        </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        )
    }
}

export default ItineraryCard;
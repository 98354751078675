// Component gets passed an SID and returns a Grid item container to display the information
import React from 'react';
import axios from 'axios';

// Import other components
import AddOnCard from '../AddOnCard/AddOnCard';

// Material UI imports
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Import Config URL
import URL from '../../../config';

class AddOnSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addOns: []
        }
    }

    // Axios POST request to get the Experience Data
    componentDidMount() {
        axios.post(`${URL}/api/v1/experience-add-on/get-experience-add-ons-with-eid-and-uid`, {
            TOID: this.props.TOID,
            EID: this.props.Experience.EID
        }, {})
        .then((response) => {
            if(response.data.length > 0) {
                // Add the experiences for the data
                this.setState({
                    addOns: response.data
                })
            }
        })
    }

    render() {
        return (
            <Grid item container xs={11} sm={8}>

                {
                    this.state.addOns.length > 0 ?
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {this.props.Experience.Title}
                        </Typography>
                    </Grid>
                    :
                    null
                }

                <Grid item container xs={12}
                justify="space-between">
                {   this.state.addOns.length > 0 ?
                    this.state.addOns.map(addOn => {
                        return (
                            <Grid item xs={12} sm={5}>
                                <AddOnCard
                                AddOnObject = {addOn}
                                TOID={this.props.TOID}
                                TourOperatorName={this.props.TourOperatorName}/>
                            </Grid>
                        )
                    }) : null
                }
                </Grid>
            </Grid>
        )
    }
}

export default AddOnSection;
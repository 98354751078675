import React from 'react';
import './ExperienceCard.css';

// Import Material UI Components
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocationMap from '../../LocationMap/LocationMap';

// Redux
import { connect } from 'react-redux';
import { addExperience, removeExperience } from '../../../actions/PackageActions';

// Image URL
import IMAGE_URL from '../../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class ExperienceCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            experienceSelected: false,
            open: false,
            name: this.props.Name,
            description: this.props.Description,
            reviewPage: this.props.ReviewPage || false
        }
    }

    componentDidMount() {
        if (this.props.selectedExperiences.findIndex(x => x.EID === this.props.ExperienceObject.EID) !== -1) {
            this.setState({
                experienceSelected: true
            })
        }
    }

    handleDialogOpen = () => {
        ReactGA.event({
            category: `${this.props.TOID} - Experience Button Click`,
            action: `${this.props.TOID} - ${this.props.ExperienceObject.EID} - More Information Clicked`,
            label: `${this.props.TourOperatorName} - ${this.props.ExperienceObject.EID} - Experience More Information`
        })

        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleSelect(ExperienceObject) {
        ReactGA.event({
            category: `${this.props.TOID} - Experience Button Click`,
            action: `${this.props.TOID} - ${this.props.ExperienceObject.EID} - Experience Selected`,
            label: `${this.props.TourOperatorName} - ${this.props.ExperienceObject.EID} - Experience Selected`
        })

        this.setState({
            experienceSelected: true
        });
        this.props.addExperience(ExperienceObject);
    }

    handleRemove(ExperienceObject) {
        ReactGA.event({
            category: `${this.props.TOID} - Experience Button Click`,
            action: `${this.props.TOID} - ${this.props.ExperienceObject.EID} - Experience Removed`,
            label: `${this.props.TourOperatorName} - ${this.props.ExperienceObject.EID} - Experience Removed`
        })

        this.setState({
            experienceSelected: false
        });
        this.props.removeExperience(ExperienceObject);
    }


    render() {
        return (
            <div>
            <Fade in={true} timeout={800}>
            <div class="SectionThemeCard-Padding">
                {
                    this.state.experienceSelected || this.state.reviewPage
                    ?
                    <div>
                        <div class="Exp-Card-Div-Heading-Selected">
                            <img src={`${IMAGE_URL}/form/${this.props.ExperienceObject.Filename}`}
                            alt={this.props.ExperienceObject.Title}
                            class="Exp-Card-Image"></img>
                            <div class="Exp-Card-Overlay-Coloured" />
                            <Typography variant="h4" className="Exp-Card-Text-Overlay">{this.props.ExperienceObject.Title}</Typography>
                        </div>
                    </div>
                    :
                    <div>
                        <div class="Exp-Card-Div-Heading-Selected">
                            <img src={`${IMAGE_URL}/form/${this.props.ExperienceObject.Filename}`}
                            alt={this.props.ExperienceObject.Title}
                            class="Exp-Card-Image"></img>
                            <div class="Exp-Card-Overlay" />
                            <Typography variant="h4" className="Exp-Card-Text-Overlay">{this.props.ExperienceObject.Title}</Typography>
                        </div>
                    </div>
                }


            <Grid container justify="space-between">
                <Grid item className="Exp-Card-Text-Spacing">
                <Typography variant="subtitle1">{this.props.ExperienceObject.RecommendedDays} {this.props.ExperienceObject.RecommendedDays > 1 ? 'Nights' : 'Night'} - {this.props.ExperienceObject.Title}</Typography>
                </Grid>
            </Grid>

            <Grid container
            justify="space-between">
                <Grid item>
                    <Button variant="outlined"
                    onClick={this.handleDialogOpen}>
                    Description
                    </Button>
                </Grid>
                <Grid item>
                    {
                        this.state.reviewPage ?
                        <Button variant="contained" color="secondary"
                        onClick={() => this.handleRemove(this.props.ExperienceObject)}>
                            Remove
                        </Button>
                        :
                        this.state.experienceSelected ?
                        <Button variant="contained" color="secondary"
                        onClick={() => this.handleRemove(this.props.ExperienceObject)}>
                            Remove
                        </Button>
                        :
                        <Button variant="outlined"
                        onClick={() => this.handleSelect(this.props.ExperienceObject)}>
                            Select
                        </Button>
                    }

                </Grid>
            </Grid>

            </div>
            </Fade>

            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.ExperienceObject.Title}
                </DialogTitle>
                <DialogContent>
                    <img src={`${IMAGE_URL}/form/${this.props.ExperienceObject.Filename}`}
                    alt={this.props.ExperienceObject.Title}
                    class="Exp-Card-Dialog-Image"></img>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.ExperienceObject.Description}
                    </DialogContentText>
                    <LocationMap
                    latitude = {this.props.ExperienceObject.Latitude}
                    longitude = {this.props.ExperienceObject.Longitude}
                    locationTitle = {this.props.ExperienceObject.LocationTitle}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            </div>
        )
    }
}

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        selectedExperiences: state.selectedExperiences
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        addExperience: (exp) => {
            dispatch(addExperience(exp))
        },
        removeExperience: (exp) => {
            dispatch(removeExperience(exp))
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ExperienceCard);
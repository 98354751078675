import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { setNumberTravelers } from '../../actions/PackageActions';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import "./Travellers.css"

// React Router Redirect
import { Redirect } from 'react-router-dom';

// Image URL
import IMAGE_URL from '../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class Travellers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            numOfTravelers: this.props.numOfTravelers || 0,
            slide: true,
            redirect: false
        };
        this.handleChange = this.handleChange.bind(this);
    };

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Number of Travellers Page View`,
            label: `${this.props.match.params.TourOperatorName} - Number of Travellers`
        })
    }

handleChange = event => {
        this.setState({
            [event.target.name]:event.target.value
        });

};

onSubmit = async (event) => {
    event.preventDefault();
    this.props.setNumberTravelers(this.state.numOfTravelers);
    await this.setState({
        slide: false,
        redirect: true
    })
};

render(){
    return(
        <div>
            {
                this.state.redirect ?
                <Redirect to={`/${this.props.match.params.TourOperatorName}/${this.props.match.params.TOID}/travel-date`} />
                :
                null
            }
        <Grid container
        justify="center"
        alignItems="center"
        direction="column"
        className="NumTravellers-Grid-Container">
            <Grid item container xs={11} sm={12}
            direction="column"
            justify="center"
            alignItems="center">
             <Grid item container
             xs={12}
             sm={8}
             justify="flex-start"
             alignItems="flex-start">
                 <Grid item>
                 {
                    this.props.profileImage ?
                    <img
                    src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                    class="NumTravellers-Logo"
                    alt={`${this.props.tourOperatorCompanyName} Logo`}/> :
                    <div class="NumTravellers-Logo-Default"></div>
                }
                 </Grid>
            </Grid>

            <Grid item container xs={12}
            justify="center"
            alignItems="center"
            direction="column"
            spacing={1}>
            <Grid item container
            xs={12}
            sm={6}>
                <Typography>
                    How many people are travelling (including you)?
                </Typography>
            </Grid>

            <Grid item container xs={12} sm={6}>
                <TextField
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                name="numOfTravelers"
                value={this.state.numOfTravelers}
                helperText="Please enter the total number of travellers including yourself."
                label="Number of Travellers"
                type="number">
                </TextField>
            </Grid>
            <Grid item container xs={12} sm={6}>
                {
                    this.state.numOfTravelers > 0 ?
                    <Button
                    onClick={this.onSubmit}
                    variant="contained"
                    color="primary">
                        Next
                    </Button> :
                    <Button
                    onClick={this.onSubmit}
                    variant="contained"
                    color="primary"
                    disabled>
                        Next
                    </Button>
                }

            </Grid>
            </Grid>
            </Grid>
        </Grid>
        </div>
    )
};

};

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        numOfTravelers: state.numberOfTravelers,
        profileImage: state.profileImage,
        tourOperatorCompanyName: state.tourOperatorCompanyName
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setNumberTravelers: (numOfTravelers) => {
            dispatch(setNumberTravelers(numOfTravelers))
        }

    }
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(Travellers);

// Import Redux Components
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Import Reducer
import reducer from './reducers/reducer';

// Set Version - UPDATE IF A NEW VERSION
const version = "V5";

// Check for the Version and Clear Storage if a New Version
if (localStorage.getItem("CSFormVersion") !== version) {
    localStorage.clear();
    localStorage.setItem("CSFormVersion", version);
}

// Config for Persisted Reducer
const persistConfig = {
    key: 'root',
    storage
}

// Persist Store
const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

// Export Store and Persistor
export default () => {
    return { store, persistor }
}
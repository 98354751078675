import React from 'react';
import './ItineraryLocalOperator.css';
import axios from 'axios';

//Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// Redux
import { connect } from 'react-redux';

// Import Config URL
import URL from '../../../config';
import IMAGE_URL from '../../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class ItineraryLocalOperator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            open: false,
            openTwo: false,
            message: "Message sent! We'll contact you as soon as possible with a quote.",
            triggered: false,
            top: 0
        }
        this.handleQuoteSubmit = this.handleQuoteSubmit.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleTwoClose = this.handleTwoClose.bind(this);
        this.handleOneClose = this.handleOneClose.bind(this);
        this.scrollUpTrigger = this.scrollUpTrigger.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollUpTrigger);
    }

    handleQuoteSubmit() {
        axios.post(`${URL}/api/v1/itinerary/send-itinerary-to-tour-operator`, {
            selectedExperiences: this.props.selectedExperiences,
            selectedExperienceAddOns: this.props.selectedExperienceAddOns,
            user: this.state.user,
            recipient: this.props.email,
            itinerary: this.props.itinerary,
            TOID: this.props.TOID,
            fullName: this.props.fullName,
            numberOfTravelers: this.props.numberOfTravelers,
            adventureStartDate: this.props.adventureStartDate,
            approximateDays: this.props.approximateDays,
            approximateDayVariance: this.props.approximateDayVariance,
            luxuryLevel: this.props.luxuryLevel,
        }, {});

        this.setState({
            openTwo: true
        })
    }

    handleOpen() {
        ReactGA.event({
            category: `${this.props.TOID} - Get a Quote Clicked`,
            action: `${this.props.TOID} - Get a Quote End Button Clicked`,
            label: `${this.props.TOName} - Get a Quote End Button Clicked`
          })

        this.setState({
            open: true
        })
    }

    handleOneClose() {
        this.setState({
            open: false
        })
    }

    handleTwoClose() {
        this.setState({
            openTwo: false,
            open: false
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]:event.target.value
        });
    };

    scrollUpTrigger = event => {
        event.preventDefault();
        let doc = document.documentElement;
        let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > this.state.top) {
            this.setState({
                top: top
            })
        } else {
            if (this.state.triggered === false) {
                this.setState({
                    open: true,
                    triggered: true
                })
            }
        }

    }

  render() {
    return (
      <div>
        <Grid container
        justify="center"
        alignItems="center">
          <Grid item container xs={11}
          justify="flex-start"
          alignItems="flex-start">
          <Typography variant="h5"><strong>your local operator</strong></Typography>
          </Grid>
        </Grid>

        <div class="itinerary-day-plan-itinerary-portion">
        <Grid item container xs={11}
          direction="row"
          alignItems="center"
          justify="center">
            <Hidden xsDown>
              <Grid item container xs={11} sm={6}
              direction="column"
              alignItems="flex-end"
              justify="flex-start">
                <div class="itinerary-local-operator-image">
                    {
                        this.props.ProfilePicture !== null ?
                        <img
                        src={`${IMAGE_URL}/logos/${this.props.ProfilePicture}`}
                        class="loading"
                        alt={this.props.TourCompanyName}/>
                        :
                        <img
                        src={`${IMAGE_URL}/logos/${this.props.ProfilePicture}`}
                        class="loading"
                        alt={this.props.TourCompanyName}/>
                    }

                </div>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item container xs={11} sm={6}
              direction="column"
              alignItems="center"
              justify="center">
                <div class="itinerary-local-operator-image">
                    {
                        this.props.ProfilePicture !== null ?
                        <img
                        src={`${IMAGE_URL}/logos/${this.props.ProfilePicture}`}
                        class="loading"
                        alt={this.props.TourCompanyName}/>
                        :
                        <img
                        src={`${IMAGE_URL}/logos/${this.props.ProfilePicture}`}
                        class="loading"
                        alt={this.props.TourCompanyName}/>
                    }
                </div>
              </Grid>
            </Hidden>
            <Grid item container xs={11} sm={6}
            direction="column"
            alignItems="flex-start"
            justify="flex-start">
              <Grid item>
              <Typography variant="subtitle1">
                  Hi! I'm {this.props.OperatorIdentity}, your travel advisor and founder of {this.props.TourCompanyName}. This is an
                  itinerary put together based on the experiences and add-ons you've selected. If you like the itinerary feel free
                  to get a quote using the button below. We're more than happy to help in any way we can.
              </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Grid container
        direction="row"
        justify="center"
        alignItems="center">
          <Grid item>
            <div class="itinerary-operator-button-spacing">
                <button class="itinerary-overview-section-button"
                onClick={this.handleOpen}>
                    get a quote
                </button>
            </div>
          </Grid>
        </Grid>

        <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                One step closer to your adventure!
            </DialogTitle>
            {
                !this.state.openTwo ?
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Fill out your email below so we can get back to you with a quote and more details.
                </DialogContentText>

                <TextField
                    autoFocus
                    name="user"
                    label="Email Address"
                    type="email"
                    value={this.state.user}
                    fullWidth
                    onChange={this.handleChange}
                    />
                </DialogContent>
                :
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.message}
                </DialogContentText>
                </DialogContent>
            }

            {
                !this.state.openTwo ?
                <DialogActions>
                <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleOneClose}
                size="small">
                    Cancel
                </Button>
                <Button
                color="primary"
                variant="outlined"
                onClick={this.handleQuoteSubmit}
                size="small">
                    Continue
                </Button>
                </DialogActions>
                :
                <DialogActions>
                <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleTwoClose}
                size="small">
                    Close
                </Button>
                </DialogActions>
            }


        </Dialog>
      </div>
    );
  }

}

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        CompanyName: state.tourOperatorCompanyName,
        ProfileImage: state.profileImage,
        OperatorName: state.tourOperatorName,
        selectedExperiences: state.selectedExperiences,
        selectedExperienceAddOns: state.selectedExperienceAddOns,
        fullName: state.fullName,
        numberOfTravelers: state.numberOfTravelers,
        adventureStartDate: state.adventureStartDate,
        approximateDays: state.approximateDays,
        approximateDayVariance: state.approximateDayVariance,
        luxuryLevel: state.luxuryLevel,
    }
}

export default connect(
    mapStateToProps, null
)(ItineraryLocalOperator);
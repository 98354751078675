import React from 'react';
import './TOIntro.css';
import axios from 'axios';

// Import Config URL
import URL from '../../config';
import IMAGE_URL from '../../image-config';

// Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

// React Router Redirect
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'


// Redux
import { connect } from 'react-redux';
import { setOperatorDetails, setTOID, resetStorage } from '../../actions/PackageActions';

class TOIntro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            TOID: this.props.match.params.TOID || null,
            operatorName: this.props.match.params.TourOperatorName || null,
            mascotName: null,
            companyName: null,
            profileImage: null,
            paid: false,
        }
    }

    // Axios Post Request with the Tour Operator ID to get the Company Information
    componentDidMount() {

        axios.post(`${URL}/api/v1/tour-operator/get-operator-form-data`, {
            TOID: this.state.TOID
        }, {})
        .then(async (response) => {

            if(response.data.length > 0) {

                // Check for the TOID in the URL
                await this.checkTOIDinHREF();

                // Redux function called to set the state for the Company Name and Operator Name
                this.props.setOperatorDetails(response.data[0].CompanyName, response.data[0].FirstName, response.data[0].ProfileImage, response.data[0].Email);


                this.setState({
                    mascotName: response.data[0].CompanyMascot || response.data[0].FirstName,
                    companyName: response.data[0].CompanyName || "CloudSafari Inc.",
                    profileImage: response.data[0].ProfileImage,
                    paid: true
                })
            }
        })
    }

    async checkTOIDinHREF() {
        let href = String(window.location.href);
        let firstIndex = String(href).indexOf(String(URL));
        let lastIndex = firstIndex + String(URL).length;
        let params = href.substring(lastIndex+2, href.length);
        let toidIndex = params.indexOf('/');
        let toid = params.substring(toidIndex+1, params.length);
        toidIndex = toid.indexOf('/');
        let id = toid;
        if (toidIndex !== -1) {
            id = toid.substring(0, toidIndex);
        }
        if (this.props.ReduxTOID !== id) {
            // Dispatch rule to Reset the Local Storage
            await this.props.resetStorage();
            await this.props.setTOID(id);
        }
    }


    render() {
        return (
            <div>
                {
                    this.state.mascotName !== null && this.state.companyName !== null && this.state.paid === true ?
                    <div class="TOIntro-Parent-Div">
                        <Grid item container xs={12}
                        justify="center"
                        alignItems="center"
                        direction="column"
                        className="TOIntro-Grid-Container">
                            <Grid item container
                            justify="center"
                            alignItems="center"
                            direction="column">
                                <Grid item>
                                    {
                                        this.state.profileImage ?
                                        <img
                                        src={`${IMAGE_URL}/logos/${this.state.profileImage}`}
                                        id="TOIntro-Logo"
                                        alt={`${this.state.companyName} Logo`}/> :
                                        <div class="TOIntro-Logo-Default"></div>
                                    }

                                </Grid>
                                <Grid item xs={11} sm={8}>
                                <Typography variant="h4"
                                className="TOIntro-Text">
                                    <Box fontWeight="600">
                                    Hi, I'm {this.state.mascotName}, your advisor for {this.state.companyName}! I'll help you plan your trip.
                                    </Box>
                                </Typography>
                                </Grid>
                                <Grid item>
                                    {
                                        this.state.operatorName !== null && this.state.TOID !== null ?
                                        <Button
                                        color="primary"
                                        variant="contained"
                                        component={Link}
                                        to={`/${this.props.match.params.TourOperatorName}/${this.props.match.params.TOID}/travellers`}
                                        size="large">
                                            Start
                                        </Button>
                                        :
                                        null
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}


// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        operatorName: state.tourOperatorName,
        operatorCompanyName: state.tourOperatorCompanyName,
        ReduxTOID: state.TOID
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setOperatorDetails: (companyName, operatorName, profileImage, email) => {
            dispatch(setOperatorDetails(companyName, operatorName, profileImage, email))
        },
        setTOID: (toid) => {
            dispatch(setTOID(toid))
        },
        resetStorage: () => {
            dispatch(resetStorage())
        }
    }
}

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(TOIntro));
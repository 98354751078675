import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { setConsumerName } from '../../actions/PackageActions';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import "./Name.css";

// React Router Redirect
import { Redirect } from 'react-router-dom';

// URL
import IMAGE_URL from '../../image-config';

// React GA
import ReactGA from 'react-ga';

class ConsumerName extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            consumerName: this.props.consumerName || '',
            slide: true,
            redirect: false
        };
        this.handleChange = this.handleChange.bind(this);
    };

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Full Name Page View`,
            label: `${this.props.match.params.TourOperatorName}`
        })
    }

    handleChange = event => {
            this.setState({
                [event.target.name]:event.target.value
            });

    };


    onSubmit = async (event) => {
        event.preventDefault();
        await this.props.setConsumerName(this.state.consumerName);
        await this.setState({
            slide: false,
            redirect: true
        })
    };

render(){
    return(
        <div class="Name-Parent-Div">
            {
                this.state.redirect ?
                <Redirect to={`/${this.props.match.params.TourOperatorName}/${this.props.match.params.TOID}/travellers`} />
                :
                null
            }
        <Grid container xs={12}
        justify="center"
        alignItems="center"
        direction="column"
        className="Name-Grid-Container">
            <Grid item container xs={11} sm={12}
            alignItems="center"
            justify="center">
                <Grid item container
                xs={12}
                sm={8}
                justify="flex-start"
                alignItems="flex-start">
                    <Grid item>
                    {
                        this.props.profileImage ?
                        <img
                        src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                        class="Name-Logo"
                        alt={`${this.props.tourOperatorCompanyName} Logo`}/> :
                        <div class="Name-Logo-Default"></div>
                    }
                    </Grid>
                </Grid>
            </Grid>
            <Slide direction="up" in={this.state.slide} mountOnEnter unmountOnExit timeout={500}>
            <Grid item container xs={11} sm={12}
            alignItems="center"
            justify="center"
            direction="column" spacing={1}>
            <Grid item container
            xs={12}
            sm={6}>
                <Typography>
                    1- What's your name?
                </Typography>
            </Grid>

            <Grid item container xs={12} sm={6}>
                <TextField
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                placeholder="Full Name"
                name="consumerName"
                value={this.state.consumerName}
                helperText="Please enter your full name."
                label="Full Name">
                </TextField>
            </Grid>
            <Grid item container xs={12} sm={6}>
                {
                    this.state.consumerName.length > 0 ?
                    <Button
                    onClick={this.onSubmit}
                    variant="contained"
                    color="primary">
                        Next
                    </Button>
                    :
                    <Button
                    variant="contained"
                    color="primary"
                    disabled>
                        Next
                    </Button>
                }

            </Grid>
            </Grid>
            </Slide>
        </Grid>
        </div>
    )
};

};

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        consumerName: state.fullName,
        profileImage: state.profileImage,
        tourOperatorCompanyName: state.tourOperatorCompanyName
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setConsumerName: (consumerName) => {
            dispatch(setConsumerName(consumerName))
        }

    };
};


export default connect(
    mapStateToProps, mapDispatchToProps
)(ConsumerName);
import React from 'react';
import './ItineraryOverviewSection.css';
import axios from 'axios';

//Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// Import Config URL
import URL from '../../../config';

// Redux
import { connect } from 'react-redux';

// Google Analytics
import ReactGA from 'react-ga';

class ItineraryOverviewSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        user: '',
        open: false,
        openTwo: false,
        message: "Message sent! We'll contact you as soon as possible with a quote."
    }
    this.handleQuoteSubmit = this.handleQuoteSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTwoClose = this.handleTwoClose.bind(this);
    this.handleOneClose = this.handleOneClose.bind(this);
  }

  handleQuoteSubmit() {    
    axios.post(`${URL}/api/v1/itinerary/send-itinerary-to-tour-operator`, {
        selectedExperiences: this.props.selectedExperiences,
        selectedExperienceAddOns: this.props.selectedExperienceAddOns,
        user: this.state.user,
        recipient: this.props.email,
        itinerary: this.props.itinerary,
        TOID: this.props.TOID,
        fullName: this.props.fullName,
        numberOfTravelers: this.props.numberOfTravelers,
        adventureStartDate: this.props.adventureStartDate,
        approximateDays: this.props.approximateDays,
        approximateDayVariance: this.props.approximateDayVariance,
        luxuryLevel: this.props.luxuryLevel,
    }, {});

    this.setState({
        openTwo: true
    })
    }

    handleOpen() {
        ReactGA.event({
          category: `${this.props.TOID} - Get a Quote Clicked`,
          action: `${this.props.TOID} - Get a Quote Overview Clicked`,
          label: `${this.props.TOName} - Get a Quote Overview Clicked`
        })
        this.setState({
            open: true
        })
    }

    handleOneClose() {
        this.setState({
            open: false
        })
    }

    handleTwoClose() {
        this.setState({
            open: false,
            openTwo: false
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]:event.target.value
        });
    };


  render() {
    return (
      <div class="itinerary-overview-section-component-spacing">
        {
          this.props.overview ?
        <Grid container
        direction="row"
        alignItems="center"
        justify="center">
          <Grid item container xs={11}
          direction="row"
          alignItems="center"
          justify="center">
            <Grid item container xs={12} sm={6}
            direction="column"
            alignItems="flex-start"
            justify="flex-start">
              <div class="itinerary-overview-section-text-spacing">
              <Typography variant="h6">{this.props.itineraryLength} - Day Experience</Typography>
              </div>
              <div class="itinerary-overview-section-text-spacing">
              <Typography variant="h4">Your Adventure</Typography>
              <Typography variant="h6">Get a quote from {this.props.companyName} for this adventure</Typography>
              </div>
              <div class="itinerary-overview-section-text-spacing">
              <button class="itinerary-overview-section-button"
              onClick={this.handleOpen}>get a quote</button>
              </div>
            </Grid>
            <Grid item container xs={12} sm={6}
            direction="column"
            alignItems="flex-start"
            justify="center">
              <Grid item>
                <div class="itinerary-overview-section-text-spacing">
                <Typography variant="subtitle1">Experience this self-designed adventure under the professional guidance of {this.props.companyName}</Typography>
                </div>
              </Grid>
              <div class="itinerary-overview-section-text-spacing">
              <Grid item container
              direction="row"
              alignItems="flex-start"
              justify="flex-start">
                <Grid item xs={3}>
                <Typography variant="subtitle2">what's included</Typography>
                <Typography variant="subtitle2"><strong>food, drinks, accommodation, park fees</strong></Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography variant="subtitle2">duration</Typography>
                <Typography variant="subtitle2"><strong>{this.props.itineraryLength} days</strong></Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography variant="subtitle2">activity level</Typography>
                <Typography variant="subtitle2"><strong>{this.props.activityLevel}/5</strong></Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography variant="subtitle2">language</Typography>
                <Typography variant="subtitle2"><strong>english, swahili</strong></Typography>
                </Grid>
              </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>:
        null
        }

      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                One step closer to your adventure!
            </DialogTitle>
            {
                !this.state.openTwo ?
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Fill out your email below so we can get back to you with a quote and more details.
                </DialogContentText>

                <TextField
                    autoFocus
                    name="user"
                    label="Email Address"
                    type="email"
                    value={this.state.user}
                    fullWidth
                    onChange={this.handleChange}
                    />
                </DialogContent>
                :
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.state.message}
                </DialogContentText>
                </DialogContent>
            }

            {
                !this.state.openTwo ?
                <DialogActions>
                <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleOneClose}
                size="small">
                    Cancel
                </Button>
                <Button
                color="primary"
                variant="outlined"
                onClick={this.handleQuoteSubmit}
                size="small">
                    Continue
                </Button>
                </DialogActions>
                :
                <DialogActions>
                <Button
                color="secondary"
                variant="outlined"
                onClick={this.handleTwoClose}
                size="small">
                    Close
                </Button>
                </DialogActions>
            }


        </Dialog>
      </div>
    )
  }
}

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
  return {
      CompanyName: state.tourOperatorCompanyName,
      ProfileImage: state.profileImage,
      OperatorName: state.tourOperatorName,
      selectedExperiences: state.selectedExperiences,
      selectedExperienceAddOns: state.selectedExperienceAddOns,
      fullName: state.fullName,
      numberOfTravelers: state.numberOfTravelers,
      adventureStartDate: state.adventureStartDate,
      approximateDays: state.approximateDays,
      approximateDayVariance: state.approximateDayVariance,
      luxuryLevel: state.luxuryLevel,
  }
}

export default connect(
  mapStateToProps, null
)(ItineraryOverviewSection);
import React from 'react';



// Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';

// Import others
import ItineraryCard from './ItineraryCard/ItineraryCard';

// React Router Redirect
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

// IMAGE URL
import IMAGE_URL from '../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

/*
    <Grid item container xs={11} sm={8}
    justify="space-between"
    alignItems="flex-start">
        {
            this.state.sections.map((sectionObject) => {
                return (
                    <Grid item xs={12} sm={5}>
                        <SectionThemeCard
                        SID={sectionObject.SID}
                        Name={sectionObject.Name}
                        Description={sectionObject.Description}
                        />
                    </Grid>
                )
            })
        }
    </Grid>
*/

class Itineraries extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            TOID: this.props.match.params.TOID,
            TOName: this.props.match.params.TourOperatorName,
            name: '',
            paid: false
        }
    }

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Itineraries Page View`,
            label: `${this.props.match.params.TourOperatorName} - Itineraries`
        })
    }
    render() {
        return (
            <div>
                {
                    this.props.ItineraryData !== null ?
                    <div class="SectionTheme-Parent-Div">
                        <Grid item container xs={12}
                        justify="flex-start"
                        alignItems="center"
                        direction="column"
                        className="SectionTheme-Grid-Container">

                                <Grid item container xs={11}
                                justify="flex-start"
                                alignItems="flex-end"
                                className="SectionTheme-Logo-Container">
                                    <Grid item xs={12} sm={2}>
                                    {
                                        this.props.profileImage !== null ?
                                        <img
                                        src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                                        class="SectionTheme-Logo"
                                        alt={`Company Logo`}/> :
                                        <div class="SectionTheme-Logo-Default"></div>
                                    }
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <Typography variant="h4"
                                        className="TOIntro-Text">
                                            <Box fontWeight="500">
                                                Check out the itineraries we've created
                                                for you based on
                                                what you selected!
                                            </Box>
                                        </Typography>
                                    </Grid>
                                </Grid>



                            <Grid item container xs={11} sm={8}
                            justify="space-between"
                            alignItems="center"
                            direction="column"
                            spacing={4}>
                                <Grid container item
                                direction="column"
                                spacing={1}>
                                    <Grid item>
                                        <Typography variant="h6">
                                        We've picked the best experiences for the time you have available so that you can get the best out of your trip.
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <ItineraryCard itinerary={this.props.ItineraryData.Recommended}
                                        TOName={this.state.TOName}
                                        TOID={this.state.TOID}
                                        fullLength = {false}/>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="p">
                                        This is our customized itinerary for your trip given the amount of days you have available, and the experiences you selected.
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {
                                    this.props.ItineraryData.Recommended.Itinerary.length !== this.props.ItineraryData.FullItinerary.Itinerary.length ?
                                <Grid container item
                                direction="column"
                                spacing={1}>
                                    <Grid item>
                                        <Typography variant="h6">
                                            If you can extend the length of your trip, we recommend doing this complete itinerary which includes everything you want.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <ItineraryCard itinerary={this.props.ItineraryData.FullItinerary}
                                        TOName = {this.state.TOName}
                                        TOID={this.state.TOID}
                                        fullLength = {true}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="p">
                                            This is another customized itinerary which includes all the experiences and add ons you've selected but is
                                            {` ${this.props.ItineraryData.FullItinerary.Itinerary.length} `}
                                            days long.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                :
                                null
                                }

                                <Grid item container>
                                    <Grid item>
                                    <Button
                                    color="secondary"
                                    variant="contained"
                                    component={Link}
                                    to={`/${this.state.TOName}/${this.state.TOID}/name`}
                                    size="large">
                                        <ArrowBack /> Restart
                                    </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}


// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        profileImage: state.profileImage,
        ItineraryData: state.data,
        fullName: state.fullName
    }
}

// This function will let you use the actions to manipulate state in the Redux store

export default connect(
    mapStateToProps, null
)(Itineraries);
import React from 'react';
import './Review.css';

// Import Material UI Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Import Other Components
import ExperienceCard from '../Experience/ExperienceCard/ExperienceCard';
import AddOnCard from '../AddOns/AddOnCard/AddOnCard';

// Image URL
import IMAGE_URL from '../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class Review extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
        TOID: this.props.match.params.TOID,
        TOName: this.props.match.params.TourOperatorName,
        selectedExperienceAddOns:[],
        selectedExperiences:[]
        }
    }

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Review Page View`,
            label: `${this.props.match.params.TourOperatorName} - Review`
        })
    }


render(){
    return(
    <div>

            <div class="SectionTheme-Parent-Div">
                <Grid item container xs={12}
                justify="flex-start"
                alignItems="center"
                direction="column"
                className="SectionTheme-Grid-Container">

                        <Grid item container xs={11}
                        justify="flex-start"
                        alignItems="flex-end"
                        className="SectionTheme-Logo-Container">
                            <Grid item xs={12} sm={2}>
                            {
                                this.props.profileImage !== null ?
                                <img
                                src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                                class="SectionTheme-Logo"
                                alt={`Company Logo`}/> :
                                <div class="SectionTheme-Logo-Default"></div>
                            }
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4"
                                className="TOIntro-Text">
                                    <Box fontWeight="600">
                                        Thanks {String(this.props.name).split(" ")[0]}!
                                        Before we create a customized itinerary for you, please review your choices.
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item container>
                            <Typography variant="h4"
                                className="TOIntro-Text">
                                    <Box fontWeight="600">
                                        Experiences
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item container xs={12}
                                direction="row"
                                alignItems="center"
                                justify="flex-start"
                                spacing={3}>

            {
                this.props.selectedExperiences.map(exp => {
                    return (
                        <Grid item xs={12} sm={4}>
                            <ExperienceCard
                            ExperienceObject={exp}
                            ReviewPage = {true}/>
                        </Grid>
                    )
                })
            }

        </Grid>
        <Grid item container>
                            <Typography variant="h4"
                                className="TOIntro-Text">
                                    <Box fontWeight="600">
                                        Add-Ons
                                    </Box>
                                </Typography>
                            </Grid>
        <Grid item container
                                direction="row"
                                alignItems="center"
                                justify="flex-start"
                                spacing={3}>

            {
                this.props.selectedExperienceAddOns.map(addon => {
                    return (
                        <Grid item xs={12} sm={4}>
                            <AddOnCard
                            AddOnObject={addon}
                            ReviewPage = {true} />
                        </Grid>
                    )
                })
            }
            </Grid>

            <Grid item container xs={12} sm={11}
                            justify="space-between"
                            alignItems="center">
                                <Grid item>
                                    <Button
                                    color="secondary"
                                    variant="contained"
                                    component={Link}
                                    to={`/${this.state.TOName}/${this.state.TOID}/experience-add-ons`}
                                    size="large">
                                        <ArrowBack /> Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {
                                        this.props.selectedExperiences.length > 0 ?
                                        <Button
                                        color="secondary"
                                        variant="contained"
                                        component={Link}
                                        to={`/${this.state.TOName}/${this.state.TOID}/itinerary`}
                                        size="large">
                                            Continue <ArrowForward />
                                        </Button>
                                        :
                                        <Button variant="contained" color="secondary"
                                        size="large" disabled>
                                            Continue <ArrowForward />
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        </Grid>

                        </div>

</div>

    )
}
}



const mapStateToProps = (state) => {
    return {
        selectedExperiences: state.selectedExperiences,
        profileImage: state.profileImage,
        selectedExperienceAddOns:state.selectedExperienceAddOns,
        name: state.fullName
    }
}

export default connect(
    mapStateToProps, null
)(Review);
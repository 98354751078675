import React from 'react';
import { connect } from 'react-redux';
import { setTravelDate } from '../../actions/PackageActions';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import "./TravelDate.css"

// React Router Redirect
import { Redirect } from 'react-router-dom';

// Image URL
import IMAGE_URL from '../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class TravelDate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            travelDate:new Date(),
            slide: true,
            redirect: false
        }
        this.handleDateChange = this.handleDateChange.bind(this);
    };

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Travel Date Page View`,
            label: `${this.props.match.params.TourOperatorName} - Travel Date`
        })
    }


handleDateChange= (travelDate)=>{
    this.setState({ travelDate })
};

onSubmit = async(event) => {
    event.preventDefault();
    await this.props.setTravelDate(this.state.travelDate);
    console.log('submit date ', this.state.travelDate);
    await this.setState({
        slide: false,
        redirect: true
    })
};


    render() {
        return (
            <div>

        {
            this.state.redirect ?
            <Redirect to={`/${this.props.match.params.TourOperatorName}/${this.props.match.params.TOID}/number-of-days`} />
            :
            null
        }
        <Grid container
        justify="center"
        alignItems="center"
        direction="column"
        className="TravelDate-Grid-Container">
            <Grid item container xs={11} sm={12}
            justify="center"
            alignItems="center"
            direction="column">
             <Grid item container
             xs={12}
             sm={8}
             justify="flex-start"
             alignItems="flex-start">
                 <Grid item>
                 {
                    this.props.profileImage ?
                    <img
                    src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                    class="TravelDate-Logo"
                    alt={`${this.props.tourOperatorCompanyName} Logo`}/> :
                    <div class="TravelDate-Logo-Default"></div>
                }
                 </Grid>
            </Grid>

            <Grid item container xs={12}
            justify="center"
            alignItems="center"
            direction="column"
            spacing={1}>
            <Grid item container
            xs={12}
            sm={6}>
                <Typography>
                    When are you thinking of traveling?
                </Typography>
            </Grid>


            <Grid item container
                xs={12}
                sm={6}>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Pick a Date"
            value={this.state.travelDate}
            onChange={this.handleDateChange}
            helperText="Please enter the approximate date you expect to travel."
            fullWidth
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            />

            </MuiPickersUtilsProvider>
            </Grid>

            <Grid item container
            xs={12}
            sm={6}>
                <Button
                onClick={this.onSubmit}
                    variant="contained"
            color="primary"
            >Next</Button>
            </Grid>
            </Grid>

            </Grid>
        </Grid>
    </div>
        )
        };
    };

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        travelDate: state.adventureStartDate,
        profileImage: state.profileImage,
        tourOperatorCompanyName: state.tourOperatorCompanyName
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setTravelDate: (travelDate) => {
            dispatch(setTravelDate(travelDate))
        }

    }
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(TravelDate);

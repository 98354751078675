import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { setLuxuryLevel } from '../../actions/PackageActions';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem'
import "./LuxuryLevel.css"


// React Router Redirect
import { Redirect } from 'react-router-dom';

// Image URL
import IMAGE_URL from '../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class LuxuryLevel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            luxLevel: this.props.luxLevel || "Luxury",
            luxuries:["Budget","Economy","Luxury"],
            slide: true,
            redirect: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Luxury Level Page View`,
            label: `${this.props.match.params.TourOperatorName} - Luxury Level`
        })
    }

handleChange = event => {
        this.setState({
            [event.target.name]:event.target.value
        });

};

onSubmit = async (event) => {
    event.preventDefault();

    ReactGA.event({
        category: `${this.props.match.params.TOID} - Luxury Level`,
        action: `${this.props.match.params.TOID} - Luxury Level - ${this.state.luxLevel}`,
        label: `${this.props.match.params.TourOperatorName} - Luxury Level - ${this.state.luxLevel}`
    })

    await this.props.setLuxuryLevel(this.state.luxLevel)

    this.setState({
        redirect: true
    })

};

render(){
    return(
        <div>
        {
            this.state.redirect ?
            <Redirect to={`/${this.props.match.params.TourOperatorName}/${this.props.match.params.TOID}/themes`} />
            :
            null
        }
        <Grid container
        justify="center"
        alignItems="center"
        direction="column"
        className="Luxury-Grid-Container">
             <Grid item container
             xs={12}
             sm={8}
             justify="flex-start"
             alignItems="flex-start">
                 <Grid item>
                 {
                    this.props.profileImage ?
                    <img
                    src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                    class="Luxury-Logo"
                    alt={`${this.props.tourOperatorCompanyName} Logo`}/> :
                    <div class="Luxury-Logo-Default"></div>
                }
                 </Grid>
            </Grid>

            <Grid item container xs={12}
            justify="center"
            alignItems="center"
            direction="column"
            spacing={1}>
            <Grid item container
            xs={12}
            sm={6}>
                <Typography>
                    5 - What level of luxury would you like for your trip?
                </Typography>
            </Grid>

            <Grid item container xs={12} sm={6}>
                <TextField
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                select
                name="luxLevel"
                value={this.state.luxLevel}
                helperText="Please enter the desired level of luxury."
                label="Luxury Level">
                    {this.state.luxuries.map(option=>(
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                </TextField>
                </Grid>
                <Grid item container xs={12} sm={6}>
                <Button
                onClick={this.onSubmit}
                variant="contained"
                color="primary">
                    Next
                </Button>
            </Grid>
            </Grid>
        </Grid>
        </div>
    )
};

};

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        luxLevel: state.luxuryLevel,
        profileImage: state.profileImage,
        tourOperatorCompanyName: state.tourOperatorCompanyName
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setLuxuryLevel: (luxLevel) => {
            dispatch(setLuxuryLevel(luxLevel))
        }

    }
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(LuxuryLevel);
// Component gets passed an SID and returns a Grid item container to display the information
import React from 'react';
import axios from 'axios';

// Import other components
import ExperienceCard from '../ExperienceCard/ExperienceCard';

// Material UI imports
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Import Config URL
import URL from '../../../config';

class ExperienceSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            experiences: []
        }
    }

    // Axios POST request to get the Experience Data
    componentDidMount() {
        axios.post(`${URL}/api/v1/experience/get-experience-data-with-sid-and-toid`, {
            TOID: this.props.TOID,
            SID: this.props.Section.SID
        }, {})
        .then((response) => {
            if(response.data.length > 0) {
                // Add the experiences for the data
                console.log(response.data);
                this.setState({
                    experiences: response.data
                })
            }
        })
    }

    render() {
        return (
            <Grid item container xs={11}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {this.props.Section.Name}
                    </Typography>
                </Grid>
                <Grid item container xs={12}
                justify="flex-start"
                alignItems="stretch"
                spacing={3}>
                {
                    this.state.experiences.length > 0 ?
                    this.state.experiences.map(exp => {
                        return (
                            <Grid item xs={12} sm={3}>
                                <ExperienceCard
                                ExperienceObject={exp}
                                TOID={this.props.TOID}
                                TourOperatorName={this.props.TourOperatorName}/>
                            </Grid>
                        )
                    })
                    :
                    null
                }
                </Grid>
            </Grid>
        )
    }
}

export default ExperienceSection;
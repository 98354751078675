// Actions
export const ADD_OPERATOR_DETAILS = 'ADD_OPERATOR_DETAILS';
export const ADD_NUMBER_OF_TRAVELERS = 'ADD_NUMBER_OF_TRAVELERS';
export const ADD_SECTION = 'ADD_SECTION';
export const REMOVE_SECTION = 'REMOVE_SECTION';
export const ADD_EXPERIENCE = 'ADD_EXPERIENCE';
export const REMOVE_EXPERIENCE = 'REMOVE_EXPERIENCE';
export const ADD_EXPERIENCE_ADD_ON = 'ADD_EXPERIENCE_ADD_ON';
export const REMOVE_EXPERIENCE_ADD_ON = 'REMOVE_EXPERIENCE_ADD_ON';
export const SET_TOID = 'SET_TOID';
export const RESET_STORAGE = '';
export const SET_ITINERARY_DATA = 'SET_ITINERARY_DATA';
export const SET_LUXURY_LEVEL = 'SET_LUXURY_LEVEL';
export const SET_NUMBER_DAYS = 'SET_NUMBER_DAYS';
export const ADD_CONSUMER_NAME = 'ADD_CONSUMER_NAME';
export const ADD_TRAVEL_DATE = 'ADD_TRAVEL_DATE';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { setNumberDays } from '../../actions/PackageActions';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import "./NumberOfDays.css";

// React Router Redirect
import { Redirect } from 'react-router-dom';

// IMAGE URL
import IMAGE_URL from '../../image-config';

// Google Analytics
import ReactGA from 'react-ga';

class NumberOfDays extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            approxDays: this.props.approxDays || 0,
            approxVariance: this.props.approxVariance || 0,
            slide: true,
            redirect: false
        };
        this.handleChange = this.handleChange.bind(this);
    };

    componentDidMount() {
        ReactGA.event({
            category: `${this.props.match.params.TOID} - Page View`,
            action: `${this.props.match.params.TOID} - Number of Days Page View`,
            label: `${this.props.match.params.TourOperatorName} - Number of Days`
        })
    }

handleChange = event => {
        this.setState({
            [event.target.name]:event.target.value
        });

};

onSubmit = async (event) => {
    event.preventDefault();
    await this.props.setNumberDays(this.state.approxDays,this.state.approxVariance);
    await this.setState({
        slide: false,
        redirect: true
    })
};

render(){
    return(
        <div>
            {
                this.state.redirect ?
                <Redirect to={`/${this.props.match.params.TourOperatorName}/${this.props.match.params.TOID}/luxury`} />
                :
                null
            }
        <Grid container
        justify="center"
        alignItems="center"
        direction="column"
        className="NumDays-Grid-Container">
            <Grid container xs={11} sm={12}
            justify="center"
            alignItems="center"
            direction="column">
             <Grid item container
             xs={12}
             sm={8}
             justify="flex-start"
             alignItems="flex-start">
                 <Grid item>
                 {
                    this.props.profileImage ?
                    <img
                    src={`${IMAGE_URL}/logos/${this.props.profileImage}`}
                    class="NumDays-Logo"
                    alt={`${this.props.tourOperatorCompanyName} Logo`}/> :
                    <div class="NumDays-Logo-Default"></div>
                }
                 </Grid>
            </Grid>
            </Grid>

            <Grid item container xs={11} sm={12}
             justify="center"
             alignItems="center"
             direction="column" spacing={1}>
            <Grid item container
            xs={12}
            sm={6}
            >
                <Typography>
                    How many days do you want to travel for?
                </Typography>
            </Grid>

            <Grid item container justify="center" xs={12} sm={6} direction="row" spacing={2}>
                <Grid item xs={12}>
                <TextField
                min={0}
                variant="outlined"
                onChange={this.handleChange}
                fullWidth
                name="approxDays"
                value={this.state.approxDays}
                helperText="Please enter the approximate number of days."
                label="Number of Days"
                type="number">
                </TextField>
                </Grid>
            </Grid>
            <Grid item container xs={12} sm={6}>
                {
                    this.state.approxDays > 0 ?
                    <Button
                    onClick={this.onSubmit}
                    variant="contained"
                    color="primary">
                        Next
                    </Button> :
                    <Button
                    onClick={this.onSubmit}
                    variant="contained"
                    color="primary"
                    disabled>
                        Next
                    </Button>
                }

            </Grid>
            </Grid>
        </Grid>
        </div>
    )
};

};

// This function will let you access state from the Redux store
const mapStateToProps = (state) => {
    return {
        approxDays: state.approximateDays,
        approxVariance:state.approximateDayVariance,
        profileImage: state.profileImage,
        tourOperatorCompanyName: state.tourOperatorCompanyName
    }
}

// This function will let you use the actions to manipulate state in the Redux store
const mapDispatchToProps = (dispatch) => {
    return {
        setNumberDays: (approxDays,approxVariance) => {
            dispatch(setNumberDays(approxDays,approxVariance))
        }

    }
}


export default connect(
    mapStateToProps, mapDispatchToProps
)(NumberOfDays);
